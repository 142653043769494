import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import DailyPrecipObsForm from "./pages/DailyPrecipObsForm";
import StationCalendar from "./pages/StationCalendar";
import StationData from "./pages/StationData";
import StationOverview from "./pages/StationOverview";
import reportWebVitals from "./reportWebVitals";
import ObsCalendar from "./pages/ObsCalendar";
import ListStations from "./pages/ListStations";
import StationView from "./components/StationView";
import StationsView from "./components/StationsView";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<ListStations />} />
        <Route path="stations" element={<StationsView />}>
          <Route path=":stationNumber" element={<StationView />}>
            <Route index element={<StationOverview />} />
            <Route
              path="calendar"
              element={<StationCalendar stationNumber="CO-LR-273" />}
            />
            <Route path="obscalendar" element={<ObsCalendar />} />
            <Route path="datasummary" element={<StationData />} />
            <Route path="obsform" element={<DailyPrecipObsForm />} />
          </Route>
        </Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>The requested page could not be found.</p>
            </main>
          }
        />
      </Route>
    </Routes>
  </BrowserRouter>
);
// <React.StrictMode>  </React.StrictMode>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
