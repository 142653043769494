import react, { useRef, useEffect, useState } from "react";
import {
  Surface,
  Text,
  Path,
  MultiPath,
  Image,
  Group,
  geometry,
  StrokeOptions,
} from "@progress/kendo-drawing";
import {
  NumericTextBox,
  NumericTextBoxChangeEvent,
  MaskedTextBox 
} from "@progress/kendo-react-inputs";
import { NumberFormatOptions } from "@progress/kendo-react-intl";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
} from "@progress/kendo-react-dateinputs";
import { isEqualDate } from "@progress/kendo-date-math";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";

function DailyPrecipObsForm() {
  const surfaceRef = useRef<HTMLDivElement>(null);
  const { Rect, Point, Size, transform } = geometry;
  //const [ surface, setSurface ] = useState<Surface>();
  let surface: Surface;
  const [precipValue, setPrecipValue] = useState<number | null>(0.4);
  const obsDate = new Date();
  const today = new Date();
  today.setHours(7, 0, 0, 0);
  const minDate = new Date(1998, 5, 17);
  const [traceButtonToggled, setTraceButtonToggled] = useState(false);
  const [missingButtonToggled, setMissingButtonToggled] = useState(false);

  const precipFormatOptions:NumberFormatOptions = {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    minimumIntegerDigits: 1
  };

  useEffect(() => {
    // setSurface(Surface.create(surfaceRef.current!));
    surface = Surface.create(surfaceRef.current!);
    drawScene(surface!, precipValue);
  }, [precipValue]);

  function drawScene(surface: Surface, value: number | null = 0) {
    // inner tube holds an inch
    // outer tube holds an additional 10 inches for a max of 11 inches
    const group = new Group();

    // Create the square border by drawing a straight path
    // const path = new Path({
    //   stroke: {
    //     color: "#9999b6",
    //     width: 2,
    //   },
    // });

    const basicStroke: StrokeOptions = {
      color: "#9999b6",
      width: 1,
    };

    let x = 2;
    let y = 0;
    const gaugeWidth = 42;
    const innerTubeWidth = 14;
    const innerTubeHeight = 100;
    const funnelTopHeight = 15;
    const funnelSlantHeight = 10;
    const tickHeight = 10;
    const tickWidth = 4;
    const innerTubeX = x + (gaugeWidth - innerTubeWidth) / 2;
    const outerTubeHeight = innerTubeHeight + funnelSlantHeight;
    const funnelWidth = gaugeWidth + 4;

    const funnelTopRect = new Rect(
      new Point(x, y),
      new Size(gaugeWidth, funnelTopHeight)
    );
    const funnelTopPath = Path.fromRect(funnelTopRect, { stroke: basicStroke });

    y = funnelTopHeight;
    const funnelBtmRect = new Rect(
      new Point(x - 2, y),
      new Size(funnelWidth, funnelTopHeight / 3)
    );
    const funnelBtmPath = Path.fromRect(funnelBtmRect, { stroke: basicStroke });
    y += funnelTopHeight / 3;

    const funnelSlant = new MultiPath({ stroke: basicStroke });
    funnelSlant
      .moveTo(x, y)
      .lineTo(innerTubeX, y + funnelSlantHeight)
      .moveTo(innerTubeX + innerTubeWidth, y + funnelSlantHeight)
      .lineTo(x + gaugeWidth, y)
      .close();

    const tickMarks = new MultiPath({ stroke: basicStroke });
    const tickY = y + funnelSlantHeight + tickHeight;
    const tickX = innerTubeX + tickWidth;
    // tickMarks.moveTo(innerTubeX, tickY)
    //     .lineTo(tickX, tickY)
    //     .moveTo(innerTubeX, tickY)
    //     .close();

    for (let i = 0; i <= 9; i++) {
      tickMarks
        .moveTo(innerTubeX, tickY + tickHeight * i)
        .lineTo(tickX, tickY + tickHeight * i)
        .moveTo(innerTubeX, tickY + tickHeight * i)
        .close();
    }

    // const funnelSlantRight = new Path({stroke: basicStroke});
    // funnelSlantRight.moveTo(innerTubeX+innerTubeWidth, y+funnelSlantHeight)
    //     .lineTo(x+gaugeWidth, y)
    //     .close();

    const outerTubeRect = new Rect(
      new Point(x, y),
      new Size(gaugeWidth, outerTubeHeight)
    );
    const outerTubePath = Path.fromRect(outerTubeRect, { stroke: basicStroke });

    y += funnelSlantHeight;

    const innerTubeRect = new Rect(
      new Point(innerTubeX, y),
      new Size(innerTubeWidth, innerTubeHeight)
    );
    const innerTubePath = Path.fromRect(innerTubeRect, { stroke: basicStroke });

    y += innerTubeHeight;

    let innerTubeFillHeight = 0;
    if (value == null) {
      value = 0;
    }
    if (value >= 1) {
      innerTubeFillHeight = innerTubeHeight;
    } else if (value > 0) {
      innerTubeFillHeight = innerTubeHeight * value;
    }
    const innerTubeFillRect = new Rect(
      new Point(innerTubeX, y),
      new Size(innerTubeWidth, -innerTubeFillHeight)
    );

    const outTubeFillRect = new Rect(
      new Point(x, y),
      new Size(gaugeWidth, -30)
    );

    // const outerFillPath = Path.fromRect(outTubeFillRect, {
    //     stroke: basicStroke,
    //     fill: {
    //         color: "#3399ff"
    //         },
    //     });

    const innerFillPath = Path.fromRect(innerTubeFillRect, {
      stroke: basicStroke,
      fill: {
        color: "#99ccff",
      },
    });

    // show overflow
    if (value > 11) {
      const overflowFillRect = new Rect(
        new Point(x - 2, y),
        new Size(funnelWidth, -(outerTubeHeight + funnelTopHeight * 1.33))
      );

      const overflowFillPath = Path.fromRect(overflowFillRect, {
        stroke: basicStroke,
        fill: {
          color: "#0066cc",
        },
      });
      group.append(overflowFillPath);
    }

    // does the outer tube need to be filled
    if (value > 1) {
      const outerTubePrecip = value - 1;
      let outerTubeFillHeight = outerTubeHeight * (outerTubePrecip / 10);
      // outerTubeFillHeight cannot be greater than the outerTubeHeight
      outerTubeFillHeight =
        outerTubeFillHeight > outerTubeHeight
          ? outerTubeHeight
          : outerTubeFillHeight;

      const outTubeFillRect = new Rect(
        new Point(x, y),
        new Size(gaugeWidth, -outerTubeFillHeight)
      );

      const outerFillPath = Path.fromRect(outTubeFillRect, {
        stroke: basicStroke,
        fill: {
          color: "#3399ff",
        },
      });
      group.append(outerFillPath);
    }

    group.append(
      funnelTopPath,
      funnelBtmPath,
      funnelSlant,
      innerTubePath,
      outerTubePath,
      innerFillPath,
      tickMarks
    );

    // // The path is constructed by using a chain of commands
    // path.moveTo(0, 0).lineTo(150, 0).lineTo(150, 65).lineTo(0, 65).close();

    // // This rectangle defines the image position and size
    // const imageRect = new Rect(new Point(5, 5), new Size(50, 50));

    // // Create the image
    // const imageUrl = `http://www.telerik.com/kendo-angular-ui/components/drawing/assets/diego.jpg`;
    // const image = new Image(imageUrl, imageRect);

    // // Create the text
    // const text = new Text("Diego Roel", new Point(60, 25), {
    //   font: "bold 15px Arial",
    // });

    // // Place all the shapes in a group
    // const group = new Group();
    // group.append(path, image, text);

    // // Translate the group
    // group.transform(transform().translate(50, 50));

    // Render the group on the surface
    surface.draw(group);
  }

  const onPrecipChange = (e: NumericTextBoxChangeEvent) => {
    setPrecipValue(e.value);
    // drawScene(surface!, e.value);
    surface.clear();
    drawScene(surface!, e.value);
    // drawScene(Surface.create(surfaceRef.current!));
  };

  const onToggleTrace = () => {
    setMissingButtonToggled(false);
    setTraceButtonToggled(!traceButtonToggled);
  }

  const onToggleMissing = () => {
    setMissingButtonToggled(!missingButtonToggled);
    setTraceButtonToggled(false);
  }

  return (
    <>
      <Card style={{ margin: 10 }}>
        <CardHeader>
          <CardTitle>Demo Obs Form Controls</CardTitle>
        </CardHeader>
        <CardBody>
          <div>
            <DatePicker
              defaultValue={obsDate}
              defaultShow={false}
              width={140}
              max={today}
              min={minDate}
            />
          </div>
          <div>
            <TimePicker width={140} defaultValue={today} />
          </div>
          <div>
            <DateTimePicker defaultValue={today} width={180} />
          </div>
          <div>
          <MaskedTextBox
        width={200}
        mask="90.09"
        defaultValue="0.00"
      />
            <ButtonGroup >
              <Button togglable={true} selected={traceButtonToggled} onClick={onToggleTrace} >
                Trace
              </Button>
              <Button togglable={true} selected={missingButtonToggled} onClick={onToggleMissing} >
                Missing
              </Button>
            </ButtonGroup>
          </div>

          <div style={{ marginTop: 20 }}>
            <NumericTextBox
              width={100}
              format={precipFormatOptions}
              value={precipValue}
              min={0}
              max={30}
              onChange={onPrecipChange}
            />
            <div ref={surfaceRef} style={{ marginTop: 20 }}></div>
          </div>
        </CardBody>
      </Card>
      <br />
    </>
  );
}

export default DailyPrecipObsForm;
