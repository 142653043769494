import React from "react";
import StationSearchForm from "../components/StationSearchForm";
import StationList from '../components/StationList'
import { useStationListDataStore } from "../zstore";
import { Outlet } from "react-router-dom";

function ListStations() {
  const stationObsData = useStationListDataStore(state => state.stationListData);
 // const fetchStationList = useStationListDataStore(state => state.fetchStationList);

  React.useEffect(() => {
   // fetchStationList("");
  }, []);

  return (
    <>
      <StationSearchForm country={""} />
      <StationList stations={stationObsData} />
      <Outlet />
    </>
  );
}

export default ListStations;
