import react, { useRef, useEffect, useState } from "react";
import {
  Surface,
  Text,
  Path,
  MultiPath,
  Image,
  Group,
  geometry,
  StrokeOptions,
} from "@progress/kendo-drawing";
import {
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { NumberFormatOptions } from "@progress/kendo-react-intl";
import StationMetadataCard from "../components/StationMetadataCard";
import RecentStationStats from "../components/RecentStationStats";
import useStationWaterYearSummary from "../hooks/useStationWaterYearSummary";
import { getDate, getMonth, differenceInDays } from "date-fns";
import { useInternationalization } from "@progress/kendo-react-intl";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardActions,
  CardImage,
  CardSubtitle,
  Avatar,
  StackLayout,
} from "@progress/kendo-react-layout";

import {
  Scheduler,
  AgendaView,
  TimelineView,
  DayView,
  WeekView,
  MonthView,
  SchedulerItem,
  SchedulerViewItem,
  SchedulerEditItem,
  SchedulerItemContent,
  SchedulerItemProps,
} from "@progress/kendo-react-scheduler";
import ObsType from "../models/ObsType";
import React from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const CustomItem = (props: SchedulerItemProps) => {
  const intl = useInternationalization();

  // const getBgColorForObsType = (obsType:string):string => {
  //   switch (obsType) {
  //     case "multidayprecip":
  //       return "lightgreen";

  //     case "cm":
  //       return "lightgray";

  //     default:
  //       return "lightblue";
  //   }
  // }

  const obsType = ObsType.getType(props.dataItem?.obsType);

  return (
    <SchedulerItem
      {...props}
      style={{
        ...props.style,
        backgroundColor: obsType.color,
      }}
    >
      <SchedulerItemContent>
        <i className={obsType.icon}></i>
        {props.dataItem?.obsType}: {props.title}
      </SchedulerItemContent>
    </SchedulerItem>
  );
};

function ObsCalendar() {
  //https://www.telerik.com/kendo-react-ui/components/scheduler/api/SchedulerModelFields/
  const intl = useInternationalization();

  const defaultDate = new Date();
  const {
    stationWys,
    stationNumber,
    setStationNumber,
    waterYear,
    setWaterYear,
    requestStatus,
    error,
  } = useStationWaterYearSummary("CO-LR-273", 2022);
  const [obsList, setObsList] = useState<any[]>([
    {
      id: 0,
      title: '0.08"',
      start: new Date("2022-07-22T08:30:00.000Z"),
      end: new Date("2022-07-22T11:00:00.000Z"),
      isAllDay: true,
      description: "dailyprecip",
      obsType: "dailyprecip",
    },
    {
      id: 2,
      title: "Near Normal",
      start: new Date("2022-07-22T08:30:00.000Z"),
      end: new Date("2022-07-22T11:00:00.000Z"),
      isAllDay: true,
      description: "cm",
      obsType: "cm",
    },
    {
      id: 1,
      title: '2.13"',
      start: new Date("2022-07-18T07:00:00.000Z"),
      end: new Date("2022-07-21T07:00:00.000Z"),
      isAllDay: true,
      description: "multidayprecip",
      obsType: "multidayprecip",
    },
  ]);

  const [obsEvents, setObsEvents] = useState<any[]>([
    {
      id: 0,
      title: 'Precip: 0.08"',
      start: new Date("2022-07-22T08:30:00.000Z"),
      end: new Date("2022-07-22T11:00:00.000Z"),
      isAllDay: true,
      description: "dailyprecip",
    },
    {
      id: 1,
      title: 'Precip: 1.13"',
      start: new Date("2022-07-18T07:00:00.000Z"),
      end: new Date("2022-07-21T07:00:00.000Z"),
      isAllDay: true,
      description: "multidayprecip",
    },
  ]);

  const modelFields: any = {
    id: "id",
    title: "title",
    start: "start",
    end: "end",
    description: "description",
  };
  // useEffect(() => {
  //   if(stationWys != undefined) {
  //     setObsEvents(stationWys["dailyPrcpObservations"].map((obs:any) => {
  //       const date = intl.parseDate(obs.obsDateTime)
  //       return {Start: date, End: date, isAllDay: true, gaugeCatch: obs.precipitation };
  //     }));
  //   }
  // }, [stationWys]);

  if (!stationWys) {
    return <></>;
  }

  return (
    <>
      <GridLayout>
        <GridLayoutItem row={1} col={1}>
          <Card style={{ width: 800, marginTop: 20, marginLeft: 20 }}>
            <CardHeader>
              <CardTitle>Obs Event Calendar</CardTitle>
            </CardHeader>
            <CardBody>
              <Scheduler
                data={obsList}
                item={CustomItem}
                defaultDate={defaultDate}
                timezone="Etc/UTC"
                height={720}
                modelFields={modelFields}
              >
                <MonthView />
              </Scheduler>
            </CardBody>
          </Card>
        </GridLayoutItem>
        <GridLayoutItem row={1} col={2}>
          <Card style={{ width: 220, marginTop: 20 }}>
            <CardHeader>
              <CardTitle>Observation Types</CardTitle>
            </CardHeader>
            <CardBody>
              <StackLayout orientation="vertical" gap={15}>
                {React.Children.toArray(
                  ObsType.options.map((ot) => {
                    return (
                      <span style={{ padding: 10, textAlign: "center" }}>
                        <i className={ot.icon + " fa-lg fa-fw"}></i>
                        <br />
                        {ot.name}
                      </span>
                    );
                  })
                )}
              </StackLayout>
            </CardBody>
          </Card>
        </GridLayoutItem>
      </GridLayout>

      {/* 
      <Scheduler data={obsList}  item={CustomItem} defaultDate={defaultDate} timezone="Etc/UTC" height={720}  modelFields={modelFields} >
        <MonthView />
      </Scheduler>
      */}
    </>
  );
}

export default ObsCalendar;
