import { useEffect, FC } from "react";
import type { RootState } from "../store";
import  store  from "../store";
import { useSelector } from "react-redux";
import PrecipCalendar from "../components/PrecipCalendar";
import PrecipCalendarLegend from "../components/PrecipCalendarLegend";
import { useStationDataStore } from '../zstore';


interface StationCalendarProps {
  stationNumber: string
}

const StationCalendar: FC<StationCalendarProps> = ({ stationNumber }) => {
//  const stationData = useSelector((rootState: RootState) => rootState.stationData );
  const stationObsData = useStationDataStore(state => state.stationObsData);

  useEffect(() => {
   // store.dispatch.stationData.getStationData({stationNumber: stationNumber});
  }, []);

  return (
    <div className="m-2">
      <h1>Station Precip Calendar</h1>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
        <div>
        <PrecipCalendar dailyObs={stationObsData.dailyObs} multidayObs={stationObsData.multidayObs} />
        </div>
        <div>
        <PrecipCalendarLegend />
        </div>
      </div>
    </div>
  );
}

export default StationCalendar;
