import React, { FC } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardActions,
  CardImage,
  CardSubtitle,
  Avatar,
} from "@progress/kendo-react-layout";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import {
  ExpansionPanel,
  ExpansionPanelContent,
  ExpansionPanelActionEvent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { useState } from "react";
import {
  Grid,
  GridColumn as Column,
  GridColumn,
} from "@progress/kendo-react-grid";
import { StationDataProps } from "./StationView";

const StationMetadataCard: FC<StationDataProps> = ({ stationData }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const getSubtitle = (isExp: boolean): string =>
    isExp ? "Collapse" : "Expand";

  const placeholderData: any[] = [
    {
      stationNumber: stationData.stationMetadata?.stationNumber,
      stationName: stationData.stationMetadata?.stationName,
      county: stationData.stationMetadata?.countyName,
      cwa: "???",
      climateDiv: "???",
    },
  ];

  return (
    <div style={{ marginTop: 20 }}>
      <ExpansionPanel
        title="Station Overview"
        subtitle={getSubtitle(isExpanded)}
        expanded={isExpanded}
        tabIndex={0}
        key={1}
        onAction={(event: ExpansionPanelActionEvent) => {
          setIsExpanded(!event.expanded);
        }}
      >
        <Reveal>
          {isExpanded && (
            <ExpansionPanelContent>
              <Grid data={placeholderData} scrollable="none">
                <GridColumn
                  field="stationNumber"
                  title="Station Number"
                ></GridColumn>
                <GridColumn
                  field="stationName"
                  title="Station Name"
                ></GridColumn>
                <GridColumn field="county" title="County"></GridColumn>
                <GridColumn field="cwa" title="NWS CWA"></GridColumn>
                <GridColumn
                  field="climateDiv"
                  title="Climate Division"
                ></GridColumn>
              </Grid>
            </ExpansionPanelContent>
          )}
          <div className="w-fit mx-4 mb-2 border border-slate-400">
            <div className="bg-gray-100 p-2">
              <span className="font-bold w-36 inline-block">
                Station Number:
              </span>
              {stationData.stationMetadata?.stationNumber}
            </div>
            <div className="p-2">
              <span className="font-bold w-36 inline-block">Station Name:</span>
              {stationData.stationMetadata?.stationName}
            </div>
            <div className="bg-gray-100 p-2">
              <span className="font-bold w-36 inline-block">County:</span>
              {stationData.stationMetadata?.countyName}
            </div>
            <div className="p-2">
              <span className="font-bold w-36 inline-block">CWA:</span>???
            </div>
            <div className="bg-gray-100 p-2">
              <span className="font-bold w-36 inline-block">
                Climate Division:
              </span>
              ???
            </div>
          </div>
        </Reveal>
      </ExpansionPanel>
    </div>
  );
};

export default StationMetadataCard;
