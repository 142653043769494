import React from 'react';
import './App.scss';
import { Outlet } from 'react-router-dom';
import AppHeader from './components/AppHeader';

function App() {
  // const fetchStaionObsData = useStationDataStore(state => state.fetchStaionObsData);
  // React.useEffect(() => {
  //   fetchStaionObsData("CO-LR-273");
  //   // loadStationData("CO-LR-273").then(result => {
  //   //   const stationData: StationObsData = {
  //   //     stationMetadata: result?.station,
  //   //     dailyObs: result?.dailyPrcpObservations.map((obs: any) => {
  //   //       return {
  //   //         obsDate: parseISO(obs.obsDateTime),
  //   //         gaugeCatch: PrecipValue.create(
  //   //           obs.precipitation,
  //   //           obs.precipitationIsTrace
  //   //         ),
  //   //       };
  //   //     }),
  //   //     multidayObs: result?.multiDayPrcpObservations.map((obs: any) => {
  //   //       return {
  //   //         obsStartDate: parseISO(obs.obsStartDate),
  //   //         obsEndDateTime: parseISO(obs.obsEndDateTime),
  //   //         gaugeCatch: PrecipValue.create(
  //   //           obs.precipitation,
  //   //           obs.precipitationIsTrace
  //   //         ),
  //   //       };
  //   //     })
  //   //   };
  //   //   setStaionObsData(stationData);
  //   //  }
  //   // );
  // }, []);

  return (
    <>
      <AppHeader />
      <Outlet />
  {/*   <Provider store={store}>

    </Provider>
    <div className="appContainer"> 
      <AppHeader />
      {/* <nav
        style={{
          borderBottom: "solid 1px",
          paddingBottom: "1rem",
        }}
      >
        <Link to="/">Station Overview</Link> |{" "}
        <Link to="/datasummary">Data Summary</Link> |{" "}
        <Link to="/calendar">Calendar</Link> |{" "}
        <Link to="/obscalendar">Obs Calendar</Link> |{" "}
        <Link to="/obsform">Obs Form</Link>
      </nav> 
      <Outlet />
     </div> */}
    </>
  );
}

export default App;
