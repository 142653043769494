import { Card, CardBody, CardHeader, CardTitle } from "@progress/kendo-react-layout";
import React, { useEffect, FC } from "react";
import { ColorPaletteType } from "../models/ColorPaletteType";
import {  keyValues } from "../models/PrecipColorCalculator";

const PrecipCalendarLegend = () => {
  return (
    <>
              <Card>
            <CardHeader>
              <CardTitle>Legend</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="grid grid-cols-2 gap-2">
                <div className="grid grid-cols-1 gap-2">
                  <div>
                    <div
                      className="border border-slate-400 inline-block rounded-sm w-6 h-6 mr-3"
                      style={{ backgroundColor: "#fef08a" }}
                    >
                      &nbsp;
                    </div>
                    <span>No Obs</span>
                  </div>
                  <div>
                    <div
                      className="border border-slate-400 inline-block rounded-sm w-6 h-6 mr-3"
                      style={{
                        backgroundColor: ColorPaletteType.green.naColor,
                      }}
                    >
                      &nbsp;
                    </div>
                    <span>NA</span>
                  </div>
                  <div>
                    <div
                      className="border border-slate-400 inline-block rounded-sm w-6 h-6 mr-3"
                      style={{
                        backgroundColor: ColorPaletteType.green.zeroColor,
                      }}
                    >
                      &nbsp;
                    </div>
                    <span>Zero</span>
                  </div>
                  <div>
                    <div
                      className="border border-slate-400 inline-block rounded-sm w-6 h-6 mr-3"
                      style={{
                        backgroundColor: ColorPaletteType.green.traceColor,
                      }}
                    >
                      &nbsp;
                    </div>
                    <span>Trace</span>
                  </div>
                  <div>
                    <div
                      className="border border-slate-400 inline-block rounded-sm w-6 h-6 mr-3"
                      style={{
                        background: `repeating-linear-gradient(
                    45deg,
                    #f3f4f6,
                    #f3f4f6 4px,
                    ${ColorPaletteType.green.precipBucketColors[2]} 4px,
                    ${ColorPaletteType.green.precipBucketColors[2]} 8px
                  )`,
                      }}
                    >
                      &nbsp;
                    </div>
                    <span>Multiday Obs</span>
                  </div>
                  <div>
                    <div
                      className="border border-slate-400 inline-block rounded-sm w-6 h-6 mr-3"
                      style={{
                        background: `repeating-linear-gradient(
                    45deg,
                    #dc2626,
                    #dc2626 4px,
                    ${ColorPaletteType.green.precipBucketColors[2]} 4px,
                    ${ColorPaletteType.green.precipBucketColors[2]} 8px
                  )`,
                      }}
                    >
                      &nbsp;
                    </div>
                    <span>Overlaping Obs</span>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-2">
                  {ColorPaletteType.green.precipBucketColors.map((c, i) => {
                    return (
                      <div key={i}>
                        <div
                          className="border border-slate-400 inline-block rounded-sm w-6 h-6 mr-3"
                          style={{ backgroundColor: c }}
                        >
                          &nbsp;
                        </div>
                        <span>
                          {keyValues[i].min.toFixed(2)} -{" "}
                          {keyValues[i].max.toFixed(2)}"
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </CardBody>
          </Card>
    </>
  );
}

export default PrecipCalendarLegend;