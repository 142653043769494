import React from "react";
import { Outlet, Link } from "react-router-dom";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
  Avatar,
} from "@progress/kendo-react-layout";
import "./AppHeader.scss";

function AppHeader() {
  return (
    <>
      <AppBar>
        <AppBarSection>
          <button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
            <span className="k-icon k-i-menu" />
          </button>
        </AppBarSection>
        <AppBarSpacer style={{ width: 4 }} />
        <AppBarSection>
          <h1 className="title">CoCoRaHS Dashboard</h1>
        </AppBarSection>

        <AppBarSpacer style={{ width: 32 }} />
        <AppBarSection>
          <ul className="app-bar-nav">
            <li>
              <Link to="/">List Stations</Link>
            </li>
            {/* 
            <li>
              <Link to="/overview">Station Overview</Link>
            </li>
            <li>
              <Link to="/datasummary">Data Summary</Link>
            </li>
            <li>
              <Link to="/calendar">Calendar</Link>
            </li>
            <li>
              <Link to="/obscalendar">Obs Calendar</Link>
            </li>
            */}
            <li>
              <Link to="/obsform">Obs Form</Link>
            </li>
          </ul>
        </AppBarSection>
        <AppBarSpacer />
      </AppBar>
    </>
  );
}

export default AppHeader;
