import React, { useEffect, FC } from "react";
import DailyPrecipitationObs from "../models/DailyPrecipitationObs";
import MultidayPrecipitationObs from "../models/MultidayPrecipitationObs";
import {
  Calendar,
  CalendarCell,
  CalendarCellProps,
  DateTimePicker,
} from "@progress/kendo-react-dateinputs";
import { classNames } from "@progress/kendo-react-common";
import { isEqualDate } from "@progress/kendo-date-math";
import { useInternationalization } from "@progress/kendo-react-intl";
import { compareAsc, isWithinInterval } from "date-fns";
import { ColorPaletteType } from "../models/ColorPaletteType";
import PrecipValue from "../models/PrecipValue";
import {
  PrecipColorCalculator,
  keyValues,
} from "../models/PrecipColorCalculator";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import Stations from "../containers/Stations";
import type { RootState } from "../store";
import  store  from "../store";
import { useSelector } from "react-redux";

interface PrecipCalendarProps {
  dailyObs: ReadonlyArray<DailyPrecipitationObs>,
  multidayObs: ReadonlyArray<MultidayPrecipitationObs>
}

const PrecipCalendar: FC<PrecipCalendarProps> = ({ dailyObs, multidayObs }) => {
  _dailyObs = dailyObs;
  _multidayObs = multidayObs;
  return (
    <>
      <Calendar cell={CustomCell} max={maxDate} />
    </>
  );
}


const maxDate = new Date();
let _dailyObs: ReadonlyArray<DailyPrecipitationObs> = [];
let _multidayObs: ReadonlyArray<MultidayPrecipitationObs> = [];

const GetTitle = (obs: any): string => {
  if (obs == null) return "No Obs";

  if (obs.gaugeCatch == null) {
    return "NA";
  }

  return obs.gaugeCatch.toString();
};

const CustomCell = (props: CalendarCellProps) => {
  // Compare the two dates and return 1 if the first date is after the second, -1 if the first date is before the second or 0 if dates are equal.
  const isFutureDate = compareAsc(props.value, maxDate) == 1;
  let bgColor = "white";
  let obs: DailyPrecipitationObs | undefined;
  let mdObs: MultidayPrecipitationObs | undefined;
  let titleValue = "";

  let style: any = {
    backgroundColor: bgColor,
  };

  // only check for obs if the date is not in the future
  if (!isFutureDate) {
    obs = _dailyObs.find((d) => isEqualDate(d.obsDate, props.value));
    mdObs = _multidayObs.find((m) =>
      isWithinInterval(props.value, {
        start: m.obsStartDate,
        end: m.obsEndDateTime,
      })
    );

    bgColor = PrecipColorCalculator.GetColor(obs?.gaugeCatch);
    titleValue = GetTitle(obs);

    if (obs) {
      style = {
        backgroundColor: bgColor,
      };
    } else if (mdObs) {
      bgColor = PrecipColorCalculator.GetColor(mdObs.gaugeCatch);
      titleValue = GetTitle(mdObs);
      style = {
        background: `repeating-linear-gradient(
          45deg,
          #f3f4f6,
          #f3f4f6 4px,
          ${bgColor} 4px,
          ${bgColor} 8px
        )`,
      };
    } else {
      style = {
        backgroundColor: bgColor,
      };
    }
  }

  // const style = {
  //   //backgroundColor: bgColor,
  //   background: `repeating-linear-gradient(
  //     45deg,
  //     #f3f4f6,
  //     #f3f4f6 4px,
  //     ${bgColor} 4px,
  //     ${bgColor} 8px
  //   )`
  // };

  const handleClick = () => {
    if (!props.isWeekend) {
      if (props.onClick) {
        props.onClick(props.value);
      }
    }
  };

  // className="bg-amber-500"
  return (
    <td style={style} onClick={handleClick}>
      <span className="k-link" title={titleValue}>
        {props.children}
      </span>
    </td>
  );

  // const title = "We're closed on the weekends!";
  // const handleClick = () => {
  //   if (!props.isWeekend) {
  //     if (props.onClick) {
  //       props.onClick(props.value);
  //     }
  //   }
  // };

  // const style = {
  //   cursor: "pointer",
  //   opacity: props.isWeekend ? 0.6 : 1,
  //   backgroundColor: props.isWeekend ? "lightgreen" : "lightgray",
  // };

  // const className = classNames({
  //   "k-selected": props.isSelected,
  //   "k-focus": props.isFocused,
  // });
  // const titleValue: string = props.isWeekend ? title : "";
  // return (
  //   <td onClick={handleClick} className={className} style={style}>
  //     <span className="k-link" title={titleValue}>
  //       {props.children}
  //     </span>
  //   </td>
  // );
};


export default PrecipCalendar;