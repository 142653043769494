class ObsType {
  constructor(public key: string, public name: string, public color: string, public icon: string) {}


  static readonly dailyPrecipObs: ObsType = new ObsType("dailyprecip", "Daily Precip", "lightblue", "fa-light fa-droplet");
  static readonly multidayPrecipObs: ObsType = new ObsType("multidayprecip", "Multi-day Precip", "lightgreen", "fa-light fa-raindrops");
  static readonly cmObs: ObsType = new ObsType("cm", "Condition Monitoring", "lightgray", "fa-light fa-sun-plant-wilt");
  static readonly sigWxObs: ObsType = new ObsType("sigwx", "Significant Weather", "lightorange", "fa-light fa-cloud-bolt");
  static readonly hailObs: ObsType = new ObsType("hail", "Hail", "lightorange", "fa-light fa-burst");
  static readonly soilMoistureObs: ObsType = new ObsType("sm", "Soil Moisture", "lightorange", "fa-light fa-trowel");
  static readonly etObs: ObsType = new ObsType("et", "Evapotranspiration", "lightorange", "fa-light fa-leaf");
  static readonly frostObs: ObsType = new ObsType("frost", "Frost", "lightorange", "fa-light fa-temperature-snow");
  static readonly opticsObs: ObsType = new ObsType("optics", "Optics", "lightorange", "fa-light fa-rainbow");
  static readonly snowflakeObs: ObsType = new ObsType("snowflake", "Snowflake", "lightorange", "fa-light fa-snowflake");
  static readonly thunderObs: ObsType = new ObsType("thunder", "Thunder", "lightorange", "fa-brands fa-mixcloud");
  static readonly iceObs: ObsType = new ObsType("ice", "Ice Accretion", "lightorange", "fa-light fa-icicles");

  static readonly default: ObsType = ObsType.dailyPrecipObs;

  static readonly options: ObsType[] = [
    ObsType.dailyPrecipObs,
    ObsType.multidayPrecipObs,
    ObsType.cmObs,
    ObsType.sigWxObs,
    ObsType.hailObs,
    ObsType.soilMoistureObs,
    ObsType.etObs,
    ObsType.frostObs,
    ObsType.opticsObs,
    ObsType.snowflakeObs,
    ObsType.thunderObs,
    ObsType.iceObs,
  ];

  static getType(key: string): ObsType {
    key = key.toLowerCase();
    const type = ObsType.options.find( item => item.key.toLowerCase() === key);

    return (type) ? type : ObsType.default;
  }
}

export default ObsType;
