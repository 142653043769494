import { parseISO } from "date-fns";
import create from "zustand";
import { loadStationData, loadStations } from "../common/api";
import { Station, StationObsData } from "../common/types";
import DailyPrecipitationObs from "../models/DailyPrecipitationObs";
import MultidayPrecipitationObs from "../models/MultidayPrecipitationObs";
import PrecipValue from "../models/PrecipValue";

export interface StationDataState {
  stationObsData: StationObsData;
  setStaionObsData: (stationData: StationObsData) => void;
  fetchStaionObsData: (stationNumber: string) => void;
}
/*
countyAbbr: "LR"
countyCode: 69
countyName: "Larimer"
elevation: 5067
id: 4119
latitude: 40.599849
longitude: -105.115094
stateAbbr: "CO"
stateCode: 8
stateName: "Colorado"
stationId: "4a9d3b19-e6a1-4788-9c5c-3ab8732fd50f"
stationName: "FCL 2.2 NW"
stationNumber: "CO-LR-273"
*/

interface StationListDataState {
  stationListData: ReadonlyArray<Station>;
  setStationList: (stations: ReadonlyArray<Station>) => void;
  fetchStationList: (state: string) => void;
}

export const useStationListDataStore = create<StationListDataState>()(
  (set) => ({
    stationListData: [],
    setStationList: (stations: ReadonlyArray<Station>) =>
      set((state) => ({
        stationListData: stations,
      })),
    fetchStationList: async (state: string) => {
      const result = await loadStations(state);
      set({ stationListData: result });
    },
  })
);

export const useStationDataStore = create<StationDataState>()((set) => ({
  stationObsData: {
    stationMetadata: null,
    dailyObs: [],
    multidayObs: [],
  },
  setStaionObsData: (stationData: StationObsData) =>
    set((state) => ({
      stationObsData: stationData,
    })),
  fetchStaionObsData: async (stationNumber: string) => {
    const result = await loadStationData(stationNumber);
    const stationData: StationObsData = {
      stationMetadata: result?.station,
      dailyObs: result?.dailyPrcpObservations.map((obs: any) => {
        return {
          obsDate: parseISO(obs.obsDateTime),
          gaugeCatch: PrecipValue.create(
            obs.precipitation,
            obs.precipitationIsTrace
          ),
        };
      }),
      multidayObs: result?.multiDayPrcpObservations.map((obs: any) => {
        return {
          obsStartDate: parseISO(obs.obsStartDate),
          obsEndDateTime: parseISO(obs.obsEndDateTime),
          gaugeCatch: PrecipValue.create(
            obs.precipitation,
            obs.precipitationIsTrace
          ),
        };
      }),
    };
    set({ stationObsData: stationData });
  },
}));
