import React, { FC } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Country } from "../models/country";
import StateProvinceLookup from "../models/StateProvince"
import { useStationDataStore, useStationListDataStore } from '../zstore';
import { StateProvince } from "../models/StateProvince";

type StationSearchFormProps = {
  country: string;
};

const StationSearchForm: FC<StationSearchFormProps> = ({ country }) => {
  const fetchStationList = useStationListDataStore(state => state.fetchStationList);

  const [selectedCountry, setSelectedCountry] = React.useState(Country.default);
  const [selectedStateProvince, setSelectedStateProvince] = React.useState<any>();
  const [stateProvinceOptions, setStateProvinceOptions] = React.useState(StateProvinceLookup.getStateProvinceByCountry(Country.default));
  //const stationObsData = useStationDataStore(state => state.);

  React.useEffect(() => {
    setSelectedStateProvince(stateProvinceOptions[0]);
  }, [])

  const handleCountryChanged = (event: DropDownListChangeEvent) => {
    setSelectedCountry(event.target.value);
    const stateOptions = StateProvinceLookup.getStateProvinceByCountry(event.target.value)
    setStateProvinceOptions(stateOptions);
    setSelectedStateProvince(stateOptions[0]);
  }
  const handleStateProvinceChanged = (event: DropDownListChangeEvent) => {
    const state: StateProvince = event.target.value;
    setSelectedStateProvince(state);
    fetchStationList(state.key);
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Station Search Form</CardTitle>
        </CardHeader>
        <CardBody>
          <DropDownList 
            style={{width: "140px"}} 
            data={Country.options} 
            textField="label"
            dataItemKey="key"
            value={selectedCountry}
            onChange={handleCountryChanged}
          />
          <DropDownList 
            style={{width: "200px"}} 
            data={stateProvinceOptions} 
            textField="label"
            dataItemKey="key"
            value={selectedStateProvince}
            onChange={handleStateProvinceChanged}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default StationSearchForm;
