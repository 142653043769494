import { Country } from "./country";

export interface StateProvince {
  key: string;
  label: string;
  country: string;
}

class StateProvinceLookup {
  static getStateProvinceByCountry(country: Country): StateProvince[] {
    const countryKey = country.key.toUpperCase();
    return stateProvinceData.filter((s) => s.country === countryKey);
  }
}

const stateProvinceData: StateProvince[] = [
  { label: "All Districts", country: "BHS", key: "BHS" },
  { label: "Acklins Islands", country: "BHS", key: "AK" },
  { label: "Berry Islands", country: "BHS", key: "BY" },
  { label: "Bimini and Cat Cay", country: "BHS", key: "BI" },
  { label: "Black Point", country: "BHS", key: "BP" },
  { label: "Cat Island", country: "BHS", key: "CI" },
  { label: "Central Abaco", country: "BHS", key: "CO" },
  { label: "Central Andros", country: "BHS", key: "CS" },
  { label: "Central Eleuthera", country: "BHS", key: "CE" },
  { label: "City of Freeport", country: "BHS", key: "FP" },
  { label: "Crooked Island and Long Cay", country: "BHS", key: "CK" },
  { label: "East Grand Bahama", country: "BHS", key: "EG" },
  { label: "Exuma", country: "BHS", key: "EX" },
  { label: "Grand Cay", country: "BHS", key: "GC" },
  { label: "Harbour Island", country: "BHS", key: "HI" },
  { label: "Hope Town", country: "BHS", key: "HT" },
  { label: "Inagua", country: "BHS", key: "IN" },
  { label: "Long Island", country: "BHS", key: "LI" },
  { label: "Mangrove Cay", country: "BHS", key: "MC" },
  { label: "Mayaguana", country: "BHS", key: "MG" },
  { label: "Moore's Island", country: "BHS", key: "MI" },
  { label: "New Providence", country: "BHS", key: "NP" },
  { label: "North Abaco", country: "BHS", key: "NO" },
  { label: "North Andros", country: "BHS", key: "NS" },
  { label: "North Eleuthera", country: "BHS", key: "NE" },
  { label: "Ragged Island", country: "BHS", key: "RI" },
  { label: "Rum Cay", country: "BHS", key: "RC" },
  { label: "San Salvador", country: "BHS", key: "SS" },
  { label: "South Abaco", country: "BHS", key: "SO" },
  { label: "South Andros", country: "BHS", key: "SA" },
  { label: "South Eleuthera", country: "BHS", key: "SE" },
  { label: "Spanish Wells", country: "BHS", key: "SW" },
  { label: "West Grand Bahama", country: "BHS", key: "WG" },
  { label: "All Provinces", country: "CAN", key: "CAN" },
  { label: "Alberta", country: "CAN", key: "AB" },
  { label: "British Columbia", country: "CAN", key: "BC" },
  { label: "Manitoba", country: "CAN", key: "MB" },
  { label: "New Brunswick", country: "CAN", key: "NB" },
  { label: "Newfoundland and Labrador", country: "CAN", key: "NL" },
  { label: "Northwest Territories", country: "CAN", key: "NT" },
  { label: "Nova Scotia", country: "CAN", key: "NS" },
  { label: "Ontario", country: "CAN", key: "ON" },
  { label: "Prince Edward Island", country: "CAN", key: "PE" },
  { label: "Quebec", country: "CAN", key: "QC" },
  { label: "Saskatchewan", country: "CAN", key: "SK" },
  { label: "Yukon", country: "CAN", key: "YT" },
  { label: "Select State", country: "USA", key: "USA" },
  { label: "Alabama", country: "USA", key: "AL" },
  { label: "Alaska", country: "USA", key: "AK" },
  { label: "Arizona", country: "USA", key: "AZ" },
  { label: "Arkansas", country: "USA", key: "AR" },
  { label: "California", country: "USA", key: "CA" },
  { label: "Colorado", country: "USA", key: "CO" },
  { label: "Connecticut", country: "USA", key: "CT" },
  { label: "Delaware", country: "USA", key: "DE" },
  { label: "District of Columbia", country: "USA", key: "DC" },
  { label: "Florida", country: "USA", key: "FL" },
  { label: "Georgia", country: "USA", key: "GA" },
  { label: "Hawaii", country: "USA", key: "HI" },
  { label: "Idaho", country: "USA", key: "ID" },
  { label: "Illinois", country: "USA", key: "IL" },
  { label: "Indiana", country: "USA", key: "IN" },
  { label: "Iowa", country: "USA", key: "IA" },
  { label: "Kansas", country: "USA", key: "KS" },
  { label: "Kentucky", country: "USA", key: "KY" },
  { label: "Louisiana", country: "USA", key: "LA" },
  { label: "Maine", country: "USA", key: "ME" },
  { label: "Maryland", country: "USA", key: "MD" },
  { label: "Massachusetts", country: "USA", key: "MA" },
  { label: "Michigan", country: "USA", key: "MI" },
  { label: "Minnesota", country: "USA", key: "MN" },
  { label: "Mississippi", country: "USA", key: "MS" },
  { label: "Missouri", country: "USA", key: "MO" },
  { label: "Montana", country: "USA", key: "MT" },
  { label: "Nebraska", country: "USA", key: "NE" },
  { label: "Nevada", country: "USA", key: "NV" },
  { label: "New Hampshire", country: "USA", key: "NH" },
  { label: "New Jersey", country: "USA", key: "NJ" },
  { label: "New Mexico", country: "USA", key: "NM" },
  { label: "New York", country: "USA", key: "NY" },
  { label: "North Carolina", country: "USA", key: "NC" },
  { label: "North Dakota", country: "USA", key: "ND" },
  { label: "Ohio", country: "USA", key: "OH" },
  { label: "Oklahoma", country: "USA", key: "OK" },
  { label: "Oregon", country: "USA", key: "OR" },
  { label: "Pennsylvania", country: "USA", key: "PA" },
  { label: "Puerto Rico", country: "USA", key: "PR" },
  { label: "Rhode Island", country: "USA", key: "RI" },
  { label: "South Carolina", country: "USA", key: "SC" },
  { label: "South Dakota", country: "USA", key: "SD" },
  { label: "Tennessee", country: "USA", key: "TN" },
  { label: "Texas", country: "USA", key: "TX" },
  { label: "Utah", country: "USA", key: "UT" },
  { label: "Vermont", country: "USA", key: "VT" },
  { label: "Virgin Islands", country: "USA", key: "VI" },
  { label: "Virginia", country: "USA", key: "VA" },
  { label: "Washington", country: "USA", key: "WA" },
  { label: "West Virginia", country: "USA", key: "WV" },
  { label: "Wisconsin", country: "USA", key: "WI" },
  { label: "Wyoming", country: "USA", key: "WY" },
];

export default StateProvinceLookup;
