export class ColorPaletteType {
  constructor(public label: string,
    public key: string,
    public naColor: string,
    public zeroColor: string,
    public traceColor: string,
    public precipBucketColors: Array<string> ) {

    }

  static readonly blue2Red: ColorPaletteType = new ColorPaletteType(
    'Blue Yellow Red', 'BluYlwRed', '#ababab', '#FFFFFF', '#deebf7',
    ['#9ecae1', '#43a2ca', '#0868ac', '#FFC800', '#E36600', '#FF0000']);
  static readonly coCoClassic: ColorPaletteType = new ColorPaletteType(
    'CoCoClassic', 'CoCoClassic', '#ababab', '#eeeeee', '#824ACC',
    ['#1E8FFE', '#000099', '#009900', '#006600', '#FF6600', '#CC3300']);
/*  static readonly prism: ColorPaletteType = new ColorPaletteType(
    'PRISM redux', 'PRISM', '#ababab', '#FFFFFF', '#FFCFFF',
    ['#DD00FF', '#36B5FF', '#68FA00', '#FFC800', '#E36600', '#800000']);
    */
  static readonly green: ColorPaletteType = new ColorPaletteType(
    'Green Gradient', 'Greens', '#e5e7eb', '#f7fcf5', '#e5f5e0',
    ['#c7e9c0', '#a1d99b', '#74c476', '#41ab5d', '#238b45', '#005a32']);
  static readonly cbrWet: ColorPaletteType = new ColorPaletteType(
    'Green Blue', 'GrnBlu', '#ababab', '#FFFFFF', '#E0F3DB'
    , ['#A8DDB5', '#7BCCC4', '#4EB3D3', '#2B8CBE', '#0868AC', '#084081']);
  static readonly grnBluRed: ColorPaletteType = new ColorPaletteType(
    'Green Blue Red', 'GrnBluRed', '#ababab', '#F7FCF0', '#E0F3DB',
    ['#A8DDB5', '#4EB3D3', '#2B8CBE', '#0868AC', '#E36600', '#FF0000']);
  static readonly bluGrnRed: ColorPaletteType = new ColorPaletteType(
    'Blue Green Red', 'BluGrnRed', '#ababab', '#eff3ff', '#c6dbef',
    ['#6baed6', '#addd8e', '#41ab5d', '#fed976', '#fd8d3c', '#e31a1c']);
  static readonly ylwGrnBlu: ColorPaletteType = new ColorPaletteType(
    'Yellow Green Blue', 'YlwGrnBlu', '#969696', '#FFFFFF', '#FFDB93',
    ['#FFFDA0', '#C0EB84', '#70E856', '#13872A', '#006E32', '#000096']);
/*    static readonly blueQc: ColorPaletteType = new ColorPaletteType(
      'Blue QC', 'bqc', '#ababab', '#FFFFFF', '#deebf7',
      ['#c6dbef', '#9ecae1', '#6baed6', '#4292c6', '#2171b5', '#cb181d']);
    static readonly greenQc: ColorPaletteType = new ColorPaletteType(
      'Green QC', 'gqc', '#ababab', '#f7fcf5', '#e5f5e0',
      ['#c7e9c0', '#a1d99b', '#74c476', '#41ab5d', '#238b45', '#cb181d']);
    static readonly blue2red2: ColorPaletteType = new ColorPaletteType(
      'Blue to Red 2', 'blue2red2', '#ababab', '#FFFFFF', '#deebf7',
      ['#9ecae1', '#6baed6', '#43a2ca', '#0868ac', '#E36600', '#FF0000']);
    static readonly cbrWetQc: ColorPaletteType = new ColorPaletteType(
      'CBR Wet QC', 'CBRWetQC', '#ababab', '#FFFFFF', '#E0F3DB',
      ['#A8DDB5', '#7BCCC4', '#4EB3D3', '#2B8CBE', '#0868AC', '#cb181d']);
    static readonly sunsetDark: ColorPaletteType = new ColorPaletteType(
      'Sunset Dark', 'sunsetDark', '#ababab', '#FFFFFF', '#fcde9c',
      ['#faa476', '#f0746e', '#e34f6f', '#dc3977', '#b9257a', '#7c1d6f']); */
    static readonly nwsQpf: ColorPaletteType = new ColorPaletteType(
      'NWS QPF', 'nwsQpf', '#ababab', '#f7fcf5', '#e5f5e0',
      ['#a1d99b', '#74c476', '#31a353', '#fffa8a', '#fc4e2a', '#ad0026']);
      static readonly nwsSnow: ColorPaletteType = new ColorPaletteType(
        'NWS Snow', 'nwsSnow', '#ababab', '#FFFFFF', '#bdd7e7',
        ['#6baed6', '#6baed6', '#082694', '#ffff96', '#ff8700', '#9e0000']);

  static readonly options: ColorPaletteType[] = [
    ColorPaletteType.blue2Red,
    ColorPaletteType.cbrWet,
  //  ColorPaletteType.prism,
    ColorPaletteType.nwsQpf,
    ColorPaletteType.nwsSnow,
    ColorPaletteType.green,
    ColorPaletteType.grnBluRed,
    ColorPaletteType.bluGrnRed,
    ColorPaletteType.ylwGrnBlu,
    ColorPaletteType.coCoClassic
    /*
    ColorPaletteType.blueQc,
    ColorPaletteType.greenQc,
    ColorPaletteType.blue2red2,
    ColorPaletteType.cbrWetQc,
    ColorPaletteType.sunsetDark,
    */
  ];

  static readonly default: ColorPaletteType = ColorPaletteType.blue2Red;

  activeStationColor = '#0868ac';
  inActiveStationColor = '#ababab';

  static getType(key: string): ColorPaletteType {
    key = key.toLowerCase();
    const type = ColorPaletteType.options.find( item => item.key.toLowerCase() === key);

    return (type) ? type : ColorPaletteType.default;
  }

  // public instace methods
  public toCartoCssColorRamp(): string {
    let css = '';

    if (this.precipBucketColors.length) {
        css = `( ${this.precipBucketColors.join(', ')}, ${this.naColor} )`;
    }

    return css;
  }
}
