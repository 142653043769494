import { useEffect, useState } from "react";
import axios from "axios";
import StationWys from "../models/StationWys";

export const REQUEST_STATUS = {
    LOADING: "loading",
    SUCCESS: "success",
    FAILURE: "failure",
  };

const restUrl = "https://wys-api.cocorahs.org/api/StationWysJson";

function useStationWaterYearSummary(initStationNumber:string, initWaterYear:number) {
    const [stationNumber, setStationNumber] = useState(typeof(initStationNumber) !== 'undefined' ? initStationNumber.toUpperCase() : null);
    const [waterYear, setWaterYear] = useState(typeof(initWaterYear) !== 'undefined' ? initWaterYear : 2021);
    const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.SUCCESS);
    const [error, setError] = useState("");
    const [stationWys, setStationWys] = useState<StationWys>();
    // todo: fetch WYS based on station number and water year

    useEffect(() => {
        async function delayFunc() {
            try {
                const result = await axios.get(`${restUrl}?stationnumber=${stationNumber}&year=${waterYear}`);

                if(result.data.station) {
                    setRequestStatus(REQUEST_STATUS.SUCCESS);
                    setStationWys(result.data);
                } else {
                    setRequestStatus(REQUEST_STATUS.FAILURE);
                    setError(`The station ${stationNumber} could not be found.`);
                }

            } catch (e) {
                setError(`An error occurred trying to retrieve the ${waterYear} water year summary report for station ${stationNumber}.`);
                /*
                if(e && e.message) {
                    setError(e.message);
                } else {
                    setError(`An error occurred trying to retrieve the ${waterYear} water year summary report for station ${stationNumber}.`);
                }*/
                setRequestStatus(REQUEST_STATUS.FAILURE);

            }
        }

        if(stationNumber && waterYear) {
            setRequestStatus(REQUEST_STATUS.LOADING);
            delayFunc();
        }
    }, [stationNumber, waterYear]);
    /*
    useEffect(() => {
        const fetchStationWys = async () => {
            const rsp = await fetch("/stationWys_CO-AD-120_2020.json");
            const wys = await rsp.json();
            setStationWys(wys);
        }
        fetchStationWys();
    }, []);
    */

    return { 
        stationWys,
        stationNumber,
        setStationNumber,
        waterYear,
        setWaterYear,
        error,
        requestStatus
    };
}

export default useStationWaterYearSummary;