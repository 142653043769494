import React from 'react';
import { Menu, MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { useNavigate } from "react-router-dom";

const StationMenu = () => {
  const navigate = useNavigate();
  const handleSelect = (event: MenuSelectEvent) => {
    navigate(event.item.data.route);
  }

  return (
    <>
    <Menu onSelect={handleSelect}>
      <MenuItem text="Station Overview" data={{ route: "" }} />
      <MenuItem text="Data Summary" data={{ route: "datasummary" }} />
      <MenuItem text="Precip Calendar" data={{ route: "calendar" }} />
      <MenuItem text="Obs Calendar" data={{ route: "obscalendar" }} />
    </Menu>    
    </>
  )
}
export default StationMenu;