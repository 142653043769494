import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { StationObsData } from "../common/types";
import { useStationDataStore } from "../zstore";
import StationMenu from "./StationMenu";

export interface StationDataProps {
  stationData: StationObsData
}

const StationView = () => {
  const station = useStationDataStore(state => state.stationObsData);
  const fetchStaionObsData = useStationDataStore(state => state.fetchStaionObsData);
  const params = useParams();
  const urlStationNumber = params.stationNumber;// (params.stationNumber)? params.stationNumber : "";

  React.useEffect(() => {
    if(urlStationNumber !== undefined && urlStationNumber !== station?.stationMetadata?.stationNumber) {
      fetchStaionObsData(urlStationNumber);
    }
  }, [])

  return (
    <>
    <div className="ml-3">Selected Station: {station?.stationMetadata?.stationNumber} : {station?.stationMetadata?.stationName}</div>
    <StationMenu />
    <Outlet />
    </>
  )
}

export default StationView;