import react, { useEffect, useRef, useState } from "react";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  Sparkline,
  StockChart,
  ChartNavigator,
  ChartNavigatorSelect,
  ChartNavigatorSeries,
  ChartNavigatorSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  CategoryBaseUnit,
} from "@progress/kendo-react-charts";
import { useInternationalization } from "@progress/kendo-react-intl";
import DailyPrecipitationObs from "../models/DailyPrecipitationObs";
import {
  Grid,
  GridColumn as Column,
  GridColumn,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import "hammerjs";
import "./StationData.scss";
import { getDate, getMonth } from "date-fns";
import {
  process,
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { useStationDataStore } from "../zstore";

// const getDatesBetweenDateRange = (startDate: Date, endDate: Date) => {
//   let dates: Date[] = [];
//   //to avoid modifying the original date
//   const theDate = new Date(startDate);
//   while (theDate <= endDate) {
//     dates.push(new Date(theDate));
//     theDate.setDate(theDate.getDate() + 1);
//   }
//   return dates;
// };

// const ChartContainer = (props: any) => (
//   <Chart>
//     <ChartSeries>
//       <ChartSeriesItem data={props.data} name="Fibonacci" />
//     </ChartSeries>
//   </Chart>
// );

const initialSort: Array<SortDescriptor> = [{ field: "obsDate", dir: "asc" }];

function StationData() {
  const stationData = useStationDataStore(state => state);

  const chartRef = useRef<Chart | null>(null);
//  const intl = useInternationalization();
  const [dailyObs, setDailyObs] = useState<DailyPrecipitationObs[]>([]);
  const [accPrecip, setAccPrecip] = useState<number[]>([]);
  //const [accTotal, setAccTotal] = useState<number>(0);
  const [monthPrecipSum, setMonthPrecipSum] = useState<any>(null);
  // const from = new Date("2021/10/01");
  // const to = new Date("2022/07/21");
  
  const [chartKey, setChartKey] = useState(new Date());
  const onRefreshButton = (e:any) => {
    setChartKey(new Date());
  };

  // const daysInRange = getDatesBetweenDateRange(from, to);
  // const daysData: IPrecipDay[] = daysInRange.map((d) => {
  //   let pVal = Math.random() * 5;
  //   if (getDate(d) > 25)
  //       pVal = 0;

  //   return { day: d, precip: pVal };
  // });

 // const navigatorData = Array.from(daysData);
  const [seriesData, setSeriesData] = useState<any[]>([]);
  const [baseUnit, setBaseUnit] = useState<CategoryBaseUnit>("days");
  const baseUnits = ["days", "weeks", "months", "years" ];

  const [chartLabelFormat, setChartLabelFormat] = useState("d");


  const data = [1, 2, 3, 5, 8, 13, 21];
  let gaugeCatch: number[] = [];
  const [sort, setSort] = useState(initialSort);
  // const {
  //   stationWys,
  //   stationNumber,
  //   setStationNumber,
  //   waterYear,
  //   setWaterYear,
  //   requestStatus,
  //   error,
  // } = useStationWaterYearSummary("CO-LR-273", 2022);

  const calcAcc = (obs: ReadonlyArray<any>): number[] => {
    const accArr: number[] = [];
    let total = 0;
    for (let i = 0; i < obs.length; i++) {
      total += obs[i].precipitation;
      accArr.push(total);
    }
    return accArr;
  };
  useEffect(() => {
    if (stationData.stationObsData.stationMetadata !== null) {
      setDailyObs(stationData.stationObsData.dailyObs.slice());

      // setAccPrecip(stationWys["dailyPrcpObservations"].reduce((acc, obs) => acc + obs.gaugeCatch, 0));
      setAccPrecip(calcAcc(stationData.stationObsData.dailyObs));

      const sd = stationData.stationObsData.dailyObs.map((obs) => { return { day: obs.obsDate, precip: obs.gaugeCatch.getValue() }});
      setSeriesData(sd);

      //   gaugeCatch = stationWys["dailyPrcpObservations"].map((obs: any) => {
      //     return obs.precipitation;
      //   });
    }
  }, [stationData]);

  useEffect(() => {
    if (dailyObs != undefined) {
      const result = process(dailyObs, {
        group: [
          {
            field: "month",
            aggregates: [{ aggregate: "sum", field: "gaugeCatch" }],
          },
        ],
      });
      setMonthPrecipSum(
        result.data.map((m) => {
          return m.aggregates.gaugeCatch.sum;
        })
      );
    }
  }, [dailyObs]);

  // const onNavigatorChange = (event: any) => {
  //   const filters: CompositeFilterDescriptor = {
  //     logic: "and",
  //     filters: [
  //       {
  //         field: "day",
  //         operator: "gte",
  //         value: event.from,
  //       },
  //       {
  //         field: "day",
  //         operator: "lte",
  //         value: event.to,
  //       },
  //     ],
  //   };

  //   setSeriesData(filterBy(navigatorData, filters));
  // };

  // const onResetZoomClicked = (e: any) => {
  //   if (chartRef.current !== null) {
  //     const chartInstance: Chart = chartRef.current.chartInstance;
  //   }
  // };

  const onBaseUnitChanged = (event: DropDownListChangeEvent) => {
    switch(event.value) {
        case "years":
            setChartLabelFormat("yyyy");
            break;
        case "months":
                setChartLabelFormat("MMM yyyy");
                break;
        default:
            setChartLabelFormat("d");
            break;
    }
    setBaseUnit(event.value);
  };

  if (!stationData) {
    return <></>;
  }

  return (
    <>
      <div>Station Data</div>
      <Button onClick={onRefreshButton}>Reset Zoom</Button>
      <div style={{ width: 200 }}>
        <label>Base Unit</label>
        <DropDownList
          style={{ marginLeft: 5 }}
          value={baseUnit}
          onChange={onBaseUnitChanged}
          data={baseUnits}
        />
      </div>
      <Chart
        ref={chartRef}
        pannable={{ lock: "y" }}
        zoomable={{ mousewheel: { lock: "y" }, selection: { lock: "y" } }}
      >
        <ChartTitle text="Days in Range" />
        <ChartSeries>
          <ChartSeriesItem
            name="Daily Precip"
            type="column"
            data={seriesData}
            field="precip"
            categoryField="day"
            aggregate="sum"
          />
        </ChartSeries>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            labels={{ format: chartLabelFormat, rotation: "auto" }}
            rangeLabels={{ format: chartLabelFormat, visible: false }}
            maxDivisions={31}
            baseUnit={baseUnit}
          />
        </ChartCategoryAxis>
      </Chart>
      {/*  categories={daysInRange} baseUnit="fit" baseUnit="months"  */}
      {/*
      <StockChart onNavigatorFilter={onNavigatorChange} partialRedraw={true}>
      <ChartTitle text="The Boeing Company NYSE:BA" />
      <ChartSeries>
        <ChartSeriesItem
          data={seriesData}
          type="column"
          field="precip"
          categoryField="Date"
        />
      </ChartSeries>
      <ChartNavigator>
        <ChartNavigatorSelect from={from} to={to} />
        <ChartNavigatorSeries>
          <ChartNavigatorSeriesItem
            data={navigatorData}
            type="area"
            field="precip"
            categoryField="Date"
          />
        </ChartNavigatorSeries>
      </ChartNavigator>
    </StockChart>

      <div>
        Spark <Sparkline data={monthPrecipSum} type="column" /> Line
      </div>
      <Chart>
        <ChartTitle text="Daily Precipitation" />
        <ChartSeries>
          <ChartSeriesItem
            type="column"
            data={dailyObs}
            field="gaugeCatch"
            name="Daily Precip"
          />
        </ChartSeries>
      </Chart>
       */}
      <Grid
        data={orderBy(dailyObs, sort)}
        scrollable="virtual"
        sortable={true}
        sort={sort}
        onSortChange={(e: GridSortChangeEvent) => {
          setSort(e.sort);
        }}
      >
        <GridColumn
          field="obsDate"
          title="Obs Date"
          format="{0:d}"
        ></GridColumn>
        <GridColumn field="gaugeCatch" title="Precipitation"></GridColumn>
      </Grid>
      {/*   <ChartContainer data={data} /> */}
    </>
  );
}

export default StationData;
