import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardActions,
  CardImage,
  CardSubtitle,
  Avatar,
  CardFooter,
} from "@progress/kendo-react-layout";
import {
  Grid,
  GridColumn as Column,
  GridColumn,
} from "@progress/kendo-react-grid";
import {
  ArcGauge,
  RadialGauge,
  RadialGaugeProps,
} from "@progress/kendo-react-gauges";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

function RecentStationStats() {
  const placeholderData: any[] = [
    {
      period: "Current Month",
      precip: 0.55,
      normalDiff: -3.2,
      obsCoverage: 11,
      possibleCoverage: 11,
    },
    {
      period: "Calendar Year",
      precip: 10.08,
      normalDiff: 3.18,
      obsCoverage: 100,
      possibleCoverage: 11,
    },
    {
      period: "Water Year",
      precip: 16.94,
      normalDiff: 0.39,
      obsCoverage: 192,
      possibleCoverage: 193,
    },
  ];

  const [value, setValue] = React.useState(85);

  //     const gaugeValue = 50;
  // const pointer = {
  //   value: gaugeValue,
  // };
  const gaugeStyles = {
    display: "block",
  };

  //     React.useEffect(() => {
  //         setValue(50); //Math.ceil(Math.random() * 100)
  //     }, []);

  const getCustomGaugeLabel = (e: any) => {
    switch (e.value) {
      case 60:
        return "Below Normal";

      case 100:
        return "Near Normal";

      case 140:
        return "Above Normal";

      case 0:
      case 20:
      case 40:
      case 80:
      case 120:
      case 160:
      case 180:
        return e.value + "%";

      case 200:
        return ">= 200%";

      default:
        return "";
    }
  };

  // const centerRenderer = (currentValue: number, color: string) => {
  //     return <h3 style={{ color: color }}>{currentValue}%</h3>;
  //   };

  const radialOptions: any = {
    pointer: { value: value, color: "black" },
    shape: "arrow",
    scale: {
      startAngle: 0,
      endAngle: 180,
      min: 0,
      max: 200,
      labels: {
        position: "outside",
        content: getCustomGaugeLabel,
      },
      minorUnit: 20,
      majorUnit: 20,
      ranges: [
        { from: 0, to: 80, color: "red" },
        { from: 80, to: 120, color: "orange" },
        { from: 120, to: 200, color: "green" },
      ],
    },
  };

  return (
    <>
      <Card className="mt-2">
        <CardHeader>
          <CardTitle>Date: 4/11/2021</CardTitle>
        </CardHeader>
        <CardBody>
          <Grid data={placeholderData} scrollable="none">
            <GridColumn field="period" title="Time Period"></GridColumn>
            <GridColumn field="precip" title="Precipitation"></GridColumn>
            <GridColumn
              field="normalDiff"
              title="Departure from Normal"
            ></GridColumn>
            <GridColumn
              field="obsCoverage"
              title="Days Covered by Obs"
            ></GridColumn>
            <GridColumn
              field="possibleCoverage"
              title="Total Possible Days"
            ></GridColumn>
          </Grid>
        </CardBody>
      </Card>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div>
          <Card>
            <CardHeader>
              <CardTitle>
                Month-To-Date Accumulated Precipitation as a Percentage of
                Normal
              </CardTitle>
            </CardHeader>
            <CardBody>
              <RadialGauge {...radialOptions} style={gaugeStyles} />
            </CardBody>
            <CardFooter>
              Days with reports: 10 Total possible Days: 13
              <br />
              Accumulated precipitation: 0.08" Normal Month-to-Date: 1.22""
              <br />
              75% of Normal
            </CardFooter>
          </Card>
        </div>
        <div>
          <Card>
            <CardHeader>
              <CardTitle>
                Year-To-Date Accumulated Precipitation as a Percentage of Normal
              </CardTitle>
            </CardHeader>
            <CardBody>
              <RadialGauge {...radialOptions} style={gaugeStyles} />
            </CardBody>
            <CardFooter>
              Days with reports: 100 Total possible Days: 101
              <br />
              Accumulated precipitation: 10.08" Normal Year-to-Date: 13.28"
              <br />
              75% of Normal
            </CardFooter>
          </Card>
        </div>
      </div>
           {/*
      <GridLayout
        align={{ horizontal: "stretch", vertical: "stretch" }}
        cols={[{ width: "repeat(2, 1fr)" }]}
        gap={{ rows: 20, cols: 20 }}
        style={{ marginTop: 20 }}
      >
        <GridLayoutItem row={1} col={1}>
          <Card>
            <CardHeader>
              <CardTitle>
                Month-To-Date Accumulated Precipitation as a Percentage of
                Normal
              </CardTitle>
            </CardHeader>
            <CardBody>
              <RadialGauge {...radialOptions} style={gaugeStyles} />
            </CardBody>
            <CardFooter>
              Days with reports: 10 Total possible Days: 13
              <br />
              Accumulated precipitation: 0.08" Normal Month-to-Date: 1.22""
              <br />
              75% of Normal
            </CardFooter>
          </Card>
        </GridLayoutItem>
        <GridLayoutItem row={1} col={2}>
          <Card>
            <CardHeader>
              <CardTitle>
                Year-To-Date Accumulated Precipitation as a Percentage of Normal
              </CardTitle>
            </CardHeader>
            <CardBody>
              <RadialGauge {...radialOptions} style={gaugeStyles} />
            </CardBody>
            <CardFooter>
              Days with reports: 100 Total possible Days: 101
              <br />
              Accumulated precipitation: 10.08" Normal Year-to-Date: 13.28"
              <br />
              75% of Normal
            </CardFooter>
          </Card>
        </GridLayoutItem>
      </GridLayout>
 
            <div className="k-card-deck">
            <Card style={{ width: 400 }} >
                <CardHeader>
                    <CardTitle>Month-To-Date Accumulated Precipitation as a Percentage of Normal</CardTitle>
                </CardHeader>
                <CardBody>
                    <RadialGauge {...radialOptions}  style={gaugeStyles}  />
                </CardBody>
                <CardFooter>
                    Days with reports: 10 Total possible Days: 13<br />
                    Accumulated precipitation: 0.08" Normal Month-to-Date: 1.22""<br />
                    75% of Normal
                </CardFooter>
            </Card>      
            <Card style={{ width: 400 }} >
                <CardHeader>
                    <CardTitle>Year-To-Date Accumulated Precipitation as a Percentage of Normal</CardTitle>
                </CardHeader>
                <CardBody>
                    <RadialGauge {...radialOptions}  style={gaugeStyles}  />
                </CardBody>
                <CardFooter>
                    Days with reports: 100 Total possible Days: 101<br />
                    Accumulated precipitation: 10.08" Normal Year-to-Date: 13.28"<br />
                    75% of Normal
                </CardFooter>
            </Card>              
            </div>
            */}
    </>
  );
}
// {...radialOptions}
export default RecentStationStats;
