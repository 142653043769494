import React from "react";
import { Outlet } from "react-router-dom";

const StationsView = () => {
  return (
    <>
    <Outlet />
    </>
  )
}

export default StationsView;