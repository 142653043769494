const NA = "NA";
const TRACE = "T";

class PrecipValue {
  /**
   * This class is meant to encapsulate the logic around precip values, such as NA, and Trace values.
   */
  // instance variables
  private _value: number | null = null;
  private _isTrace: boolean = false;

  constructor(value: number, isTrace: boolean) {
    this._value = value;
    this._isTrace = isTrace;
  }

  public static create(value: number, isTrace: boolean) {
    return new PrecipValue(value, isTrace);
  }

  public toString(): string {
    if (this._value === null) return NA;
    else if (this._isTrace) return TRACE;
    else return this._value.toFixed(2);
  }

  public getValue(): number {
    if (this._value === null) return 0;
    else if (this._isTrace) return 0;
    else return this._value;
  }

  public isTrace(): boolean {
    return this._isTrace;
  }
  public isNa(): boolean {
    return (this._value === null);
  }
}

export default PrecipValue;
