import { Station } from './types'
import axios from "axios";

// type UserResponse = {
//   results: ReadonlyArray<Station>
// }

export const loadStations = async (state: string) => {
  const result = await axios.get(`https://wys-api.cocorahs.org/api/StateWysJson?state=${state}&year=2022`)
  return result.data["stationWaterYearTotals"].map((s: { stationNumber: string, stationName: string }) => ({ stationNumber: s.stationNumber, stationName: s.stationName })) as ReadonlyArray<Station>
}

export const loadStationData = async (stationNumber: string) => {
  const result = await axios.get(`https://wys-api.cocorahs.org/api/StationWysJson?stationnumber=${stationNumber}&year=2022`)
  return result.data;
}
