export class Country {
  /**
   *
   */
  constructor(public key: string, public label: string, public districtLabel: string) { }

  static readonly usa: Country = new Country("usa", "USA", "State");
  static readonly can: Country = new Country("can", "Canada", "Province");
  static readonly bhs: Country = new Country("bhs", "Bahamas", "District");

  static readonly default: Country = Country.usa;
  static readonly options: Country[] = [
    Country.usa,
    Country.can,
    Country.bhs,
  ]

  static getType(key: string): Country {
    key = key.toLowerCase();
    const type = Country.options.find( item => item.key.toLowerCase() === key);

    return (type) ? type : Country.default;
  }

}