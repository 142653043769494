import React, { FC } from 'react';
import StationMetadataCard from '../components/StationMetadataCard';
import RecentStationStats from '../components/RecentStationStats';
import { useStationDataStore } from '../zstore';
import { StationDataProps } from '../components/StationView';

const StationOverview = () => {
    const stationData = useStationDataStore(state => state.stationObsData);
return (
        <>
            <StationMetadataCard stationData={stationData} />
            <RecentStationStats />
        </>
    );
}

export default StationOverview;