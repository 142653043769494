import { ColorPaletteType } from "./ColorPaletteType";
import PrecipValue from "./PrecipValue";

interface ValueRange {
  min: number;
  max: number;
}
export const keyValues: ValueRange[] = [
  { min: 0.01, max: 0.09 },
  { min: 0.1, max: 0.24 },
  { min: 0.25, max: 0.99 },
  { min: 1.0, max: 1.99 },
  { min: 2.0, max: 3.99 },
  { min: 4, max: 40 },
];

export class PrecipColorCalculator {
  /**
   *
   */
  constructor() {}

  static GetColor(precipVal: PrecipValue | undefined): string {
    if (precipVal === undefined) return "#fef08a";

    if (precipVal.isNa()) return ColorPaletteType.green.naColor;
    //return "#eeeeee";

    if (precipVal.isTrace()) return ColorPaletteType.green.traceColor;

    if (precipVal.getValue() === 0)
      return ColorPaletteType.green.zeroColor;

      // find the value index that the precip values in between
    const valueIndex = keyValues.findIndex((el, i, arr) => {
      return (precipVal.getValue() <= el.max || i === arr.length-1);
    });

    if (valueIndex >= 0)
      return ColorPaletteType.green.precipBucketColors[valueIndex];

    return ColorPaletteType.green.zeroColor;
  }
}
