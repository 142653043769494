import React, { FC } from 'react'
import { Station } from '../common/types'
// import { useStationDataStore } from '../zstore'
import { Link, useNavigate } from "react-router-dom";
import { Button } from '@progress/kendo-react-buttons';

type StationListProps = {
  stations: ReadonlyArray<Station>
}

const StationList: FC<StationListProps> = ({ stations }) => {
  const navigate = useNavigate();
//  const fetchStaionObsData = useStationDataStore(state => state.fetchStaionObsData);
  const selectStation = (stationNumber:string) => {
//    fetchStaionObsData(stationNumber);
    navigate(`/stations/${stationNumber}`);
  }

// 
  return (
    <div>
      {stations.map(s => (
        <div key={s.stationNumber}><Button onClick={() => {selectStation(s.stationNumber)}} >{s.stationNumber}: {s.stationName}</Button></div>
      ))}
    </div>
  )
}

export default StationList